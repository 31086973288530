/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  REQUEST_MINIMUM_USAGE_EXCEPTIONS, GET_MINIMUM_USAGE_EXCEPTIONS
} from 'src/actions/minimumUsageExceptionActions';

const initialState = {
  items: [],
  isFetching: false,
  message: ''
};

const minimumUsageExceptionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_MINIMUM_USAGE_EXCEPTIONS: {
      return produce(state, (draft) => {
        draft.isFetching = true;
      });
    }

    case GET_MINIMUM_USAGE_EXCEPTIONS: {
      const { minimumUsageExceptions, message } = action.payload;

      return produce(state, (draft) => {
        draft.items = minimumUsageExceptions;
        draft.isFetching = false;
        draft.message = message;
      });
    }

    default: {
      return state;
    }
  }
};

export default minimumUsageExceptionsReducer;
