/* eslint-disable react/no-array-index-key */
import React, {
  lazy,
  Suspense,
  Fragment
} from 'react';
import {
  Switch,
  Redirect,
  Route
} from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';
import { useSelector } from 'react-redux';

const routesConfig = [
  {
    exact: true,
    path: '/',
    component: () => <Redirect to="/login" />
  },
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/pages/Error404View')),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView')),
  },
  {
    exact: true,
    path: '/2fa',
    component: lazy(() => import('src/views/auth/TwoFactorAuthenticationView')),
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app',
        component: () => {
          const account = useSelector((state) => state.account);

          if (account?.user?.email === 'tiltekt@skolamatur.is') {
            return <Redirect to="/app/purchase-orders" />
          } else {
            return <Redirect to="/app/dashboard" />
          }
        }
      },
      {
        exact: true,
        path: '/app/dashboard',
        component: lazy(() => import('src/views/dashboard')),
        permissions: []
      },
      {
        exact: true,
        path: '/app/profile',
        component: lazy(() => import('src/views/auth/ProfileView')),
        permissions: []
      },
      {
        exact: true,
        path: '/app/customers',
        component: lazy(() => import('src/views/customers/CustomerListView')),
        permissions: [
          [{ name: 'CUSTOMERS', full: false }]
        ]
      },
      {
        exact: true,
        path: '/app/customers/add',
        component: lazy(() => import('src/views/customers/CustomerCreateView')),
        permissions: [
          [{ name: 'CUSTOMERS', full: false }]
        ]
      },
      {
        exact: true,
        path: '/app/customers/:customerId',
        component: lazy(() => import('src/views/customers/CustomerDetailsView')),
        permissions: [
          [{ name: 'CUSTOMERS', full: false }]
        ]
      },
      {
        exact: true,
        path: '/app/customers/:customerId/edit',
        component: lazy(() => import('src/views/customers/CustomerEditView')),
        permissions: [
          [{ name: 'CUSTOMERS', full: false }]
        ]
      },
      {
        exact: true,
        path: '/app/users',
        component: lazy(() => import('src/views/users/UserListView')),
        permissions: [
          [{ name: 'ADMIN', full: false }]
        ]
      },
      {
        exact: true,
        path: '/app/users/add',
        component: lazy(() => import('src/views/users/UserCreateView')),
        permissions: [
          [{ name: 'ADMIN', full: false }]
        ]
      },
      {
        exact: true,
        path: '/app/users/:userId',
        component: lazy(() => import('src/views/users/UserDetailsView')),
        permissions: [
          [{ name: 'ADMIN', full: false }]
        ]
      },
      {
        exact: true,
        path: '/app/users/:userId/edit',
        component: lazy(() => import('src/views/users/UserEditView')),
        permissions: [
          [{ name: 'ADMIN', full: false }]
        ]
      },
      {
        exact: true,
        path: '/app/branches',
        component: lazy(() => import('src/views/branches/BranchListView')),
        permissions: [
          [{ name: 'BRANCHES', full: false }]
        ]
      },
      {
        exact: true,
        path: '/app/branches/add',
        component: lazy(() => import('src/views/branches/BranchCreateView')),
        permissions: [
          [{ name: 'BRANCHES', full: true }]
        ]
      },
      {
        exact: true,
        path: '/app/branches/order',
        component: lazy(() => import('src/views/branches/BranchOrderView')),
        permissions: [
          [{ name: 'BRANCHES', full: false }]
        ]
      },
      {
        exact: true,
        path: '/app/branches/:branchId',
        component: lazy(() => import('src/views/branches/BranchDetailsView')),
        permissions: [
          [{ name: 'BRANCHES', full: false }]
        ]
      },
      {
        exact: true,
        path: '/app/branches/:branchId/edit',
        component: lazy(() => import('src/views/branches/BranchEditView')),
        permissions: [
          [{ name: 'BRANCHES', full: true }]
        ]
      },
      {
        exact: true,
        path: '/app/branches/:branchId/group/:groupId',
        component: lazy(() => import('src/views/branches/BranchGroupDetailsView')),
        permissions: [
          [{ name: 'BRANCHES', full: false }]
        ]
      },
      {
        exact: true,
        path: '/app/branches/:branchId/group/:groupId/edit',
        component: lazy(() => import('src/views/branches/BranchGroupEditView')),
        permissions: [
          [{ name: 'BRANCHES', full: false }]
        ]
      },
      {
        exact: true,
        path: '/app/menus',
        component: lazy(() => import('src/views/menus/MenuListView')),
        permissions: [
          [{ name: 'MENU', full: false }]
        ]
      },
      {
        exact: true,
        path: '/app/products',
        component: lazy(() => import('src/views/products/ProductListView')),
        permissions: [
          [{ name: 'PRODUCTS', full: false }]
        ]
      },
      {
        exact: true,
        path: '/app/products/add',
        component: lazy(() => import('src/views/products/ProductCreateView')),
        permissions: [
          [{ name: 'PRODUCTS', full: true }]
        ]
      },
      {
        exact: true,
        path: '/app/products/categories/order',
        component: lazy(() => import('src/views/products/ProductCategoryOrderView')),
        permissions: [
          [{ name: 'SETTINGS', full: false }, { name: 'SUBSCRIPTIONS', full: false }],
          [{ name: 'SETTINGS', full: false }, { name: 'PRODUCTION', full: false }],
        ]
      },
      {
        exact: true,
        path: '/app/products/categories',
        component: lazy(() => import('src/views/products/ProductCategoryListView')),
        permissions: [
          [{ name: 'SETTINGS', full: false }, { name: 'SUBSCRIPTIONS', full: false }],
          [{ name: 'SETTINGS', full: false }, { name: 'PRODUCTION', full: false }],
        ]
      },
      {
        exact: true,
        path: '/app/products/:productId',
        component: lazy(() => import('src/views/products/ProductDetailsView')),
        permissions: [
          [{ name: 'PRODUCTS', full: false }]
        ]
      },
      {
        exact: true,
        path: '/app/products/:productId/edit',
        component: lazy(() => import('src/views/products/ProductEditView')),
        permissions: [
          [{ name: 'PRODUCTS', full: true }]
        ]
      },
      {
        exact: true,
        path: '/app/purchase-orders',
        component: lazy(() => import('src/views/purchase-orders/OrderListView')),
        permissions: [
          [{ name: 'ORDERS', full: false }]
        ]
      },
      {
        exact: true,
        path: '/app/purchase-orders/add',
        component: lazy(() => import('src/views/purchase-orders/OrderAddView')),
        permissions: [
          [{ name: 'ORDERS', full: true }]
        ]
      },
      {
        exact: true,
        path: '/app/purchase-orders/overview',
        component: lazy(() => import('src/views/purchase-orders/OrderOverviewView')),
        permissions: [
          [{ name: 'ORDERS', full: false }]
        ]
      },
      {
        exact: true,
        path: '/app/purchase-orders/:purchaseOrderId',
        component: lazy(() => import('src/views/purchase-orders/OrderDetailsView')),
        permissions: [
          [{ name: 'ORDERS', full: false }]
        ]
      },
      {
        exact: true,
        path: '/app/purchase-orders/:purchaseOrderId/edit',
        component: lazy(() => import('src/views/purchase-orders/OrderEditView')),
        permissions: [
          [{ name: 'ORDERS', full: true }]
        ]
      },
      {
        exact: true,
        path: '/app/quantity-figures',
        component: lazy(() => import('src/views/quantity-figures')),
        permissions: [
          [{ name: 'PRODUCTION', full: true }]
        ]
      },
      {
        exact: true,
        path: '/app/recipes',
        component: lazy(() => import('src/views/recipes/RecipeListView')),
        permissions: [
          [{ name: 'MENU', full: false }]
        ]
      },
      {
        exact: true,
        path: '/app/recipes/add',
        component: lazy(() => import('src/views/recipes/RecipeCreateView')),
        permissions: [
          [{ name: 'MENU', full: true }]
        ]
      },
      {
        exact: true,
        path: '/app/recipes/categories/order',
        component: lazy(() => import('src/views/recipes/RecipeCategoryOrderView')),
        permissions: [
          [{ name: 'MENU', full: false }],
          [{ name: 'SETTINGS', full: false }, { name: 'PRODUCTION', full: false }],
        ]
      },
      {
        exact: true,
        path: '/app/recipes/categories',
        component: lazy(() => import('src/views/recipes/RecipeCategoryListView')),
        permissions: [
          [{ name: 'MENU', full: false }],
          [{ name: 'SETTINGS', full: false }, { name: 'PRODUCTION', full: false }],
        ]
      },
      {
        exact: true,
        path: '/app/recipes/:recipeId',
        component: lazy(() => import('src/views/recipes/RecipeDetailsView')),
        permissions: [
          [{ name: 'MENU', full: false }]
        ]
      },
      {
        exact: true,
        path: '/app/recipes/:recipeId/edit',
        component: lazy(() => import('src/views/recipes/RecipeEditView')),
        permissions: [
          [{ name: 'MENU', full: true }]
        ]
      },
      {
        exact: true,
        path: '/app/tasks',
        component: lazy(() => import('src/views/tasks/TaskListView')),
        permissions: []
      },
      {
        exact: true,
        path: '/app/tasks/:taskId',
        component: lazy(() => import('src/views/tasks/TaskDetailsView')),
        permissions: []
      },
      {
        exact: true,
        path: '/app/holidays',
        component: lazy(() => import('src/views/holidays/CalendarView')),
        permissions: [
          [{ name: 'HOLIDAYS', full: false }]
        ]
      },
      {
        exact: true,
        path: '/app/holidays/add',
        component: lazy(() => import('src/views/holidays/HolidayCreateView')),
        permissions: [
          [{ name: 'HOLIDAYS', full: true }]
        ]
      },
      {
        exact: true,
        path: '/app/subscriptions',
        component: lazy(() => import('src/views/subscriptions/SubscriptionListView')),
        permissions: [
          [{ name: 'SUBSCRIPTIONS', full: false }]
        ]
      },
      {
        exact: true,
        path: '/app/subscriptions/add',
        component: lazy(() => import('src/views/subscriptions/SubscriptionCreateView')),
        permissions: [
          [{ name: 'SUBSCRIPTIONS', full: true }]
        ]
      },
      {
        exact: true,
        path: '/app/subscriptions/:subscriptionId',
        component: lazy(() => import('src/views/subscriptions/SubscriptionDetailsView')),
        permissions: [
          [{ name: 'SUBSCRIPTIONS', full: false }]
        ]
      },
      {
        exact: true,
        path: '/app/subscriptions/:subscriptionId/edit',
        component: lazy(() => import('src/views/subscriptions/SubscriptionEditView')),
        permissions: [
          [{ name: 'SUBSCRIPTIONS', full: true }]
        ]
      },
      {
        exact: true,
        path: '/app/ticket-orders',
        component: lazy(() => import('src/views/ticketOrders/TicketOrderListView')),
        permissions: [
          [{ name: 'SUBSCRIPTIONS', full: true }]
        ]
      },
      {
        exact: true,
        path: '/app/ticket-orders/add',
        component: lazy(() => import('src/views/ticketOrders/TicketOrderAddView')),
        permissions: [
          [{ name: 'SUBSCRIPTIONS', full: true }]
        ]
      },
      {
        exact: true,
        path: '/app/ticket-orders/:ticketOrderId',
        component: lazy(() => import('src/views/ticketOrders/TicketOrderDetailsView')),
        permissions: [
          [{ name: 'SUBSCRIPTIONS', full: true }]
        ]
      },
      {
        exact: true,
        path: '/app/ticket-orders/:ticketOrderId/edit',
        component: lazy(() => import('src/views/ticketOrders/TicketOrderEditView')),
        permissions: [
          [{ name: 'SUBSCRIPTIONS', full: true }]
        ]
      },
      {
        exact: true,
        path: '/app/invoices',
        component: lazy(() => import('src/views/invoices/InvoiceListView')),
        permissions: [
          [{ name: 'BILLING', full: false }]
        ]
      },
      {
        exact: true,
        path: '/app/invoices/new',
        component: lazy(() => import('src/views/invoices/InvoiceNewListView')),
        permissions: [
          [{ name: 'BILLING', full: false }]
        ]
      },
      {
        exact: true,
        path: '/app/invoices/ticket-orders',
        component: lazy(() => import('src/views/invoices/InvoiceTicketOrdersListView')),
        permissions: [
          [{ name: 'BILLING', full: false }]
        ]
      },
      {
        exact: true,
        path: '/app/invoices/:invoiceId',
        component: lazy(() => import('src/views/invoices/InvoiceDetailsView')),
        permissions: [
          [{ name: 'BILLING', full: false }]
        ]
      },
      {
        exact: true,
        path: '/app/payment-batches',
        component: lazy(() => import('src/views/invoices/PaymentBatchListView')),
        permissions: [
          [{ name: 'BILLING', full: false }]
        ]
      },
      {
        exact: true,
        path: '/app/payment-batches/:paymentBatchId',
        component: lazy(() => import('src/views/invoices/PaymentBatchDetailsView')),
        permissions: [
          [{ name: 'BILLING', full: false }]
        ]
      },
      {
        exact: true,
        path: '/app/refund-queues',
        component: lazy(() => import('src/views/invoices/RefundQueueListView')),
        permissions: [
          [{ name: 'BILLING', full: false }]
        ]
      },
      {
        exact: true,
        path: '/app/refund-queues/:refundQueueId',
        component: lazy(() => import('src/views/invoices/RefundQueueDetailsView')),
        permissions: [
          [{ name: 'BILLING', full: false }]
        ]
      },
      {
        exact: true,
        path: '/app/suspended',
        component: lazy(() => import('src/views/reports/SuspensionView')),
        permissions: [
          [{ name: 'BILLING', full: false }]
        ]
      },
      {
        exact: true,
        path: '/app/reports/quantity',
        component: lazy(() => import('src/views/reports/QuantityByBranchView')),
        permissions: [
          [{ name: 'REPORTS', full: false }, { name: 'SUBSCRIPTIONS', full: false }]
        ]
      },
      {
        exact: true,
        path: '/app/reports/allergy',
        component: lazy(() => import('src/views/reports/AllergyView')),
        permissions: [
          [{ name: 'REPORTS', full: false }, { name: 'SUBSCRIPTIONS', full: false }]
        ]
      },
      {
        exact: true,
        path: '/app/reports/vegan',
        component: lazy(() => import('src/views/reports/VeganView')),
        permissions: [
          [{ name: 'REPORTS', full: false }, { name: 'SUBSCRIPTIONS', full: false }]
        ]
      },
      {
        exact: true,
        path: '/app/reports/allocation',
        component: lazy(() => import('src/views/reports/AllocationView')),
        permissions: [
          [{ name: 'REPORTS', full: false }, { name: 'SUBSCRIPTIONS', full: false }]
        ]
      },
      {
        exact: true,
        path: '/app/reports/siblingDiscount',
        component: lazy(() => import('src/views/reports/SiblingDiscountView')),
        permissions: [
          [{ name: 'REPORTS', full: false }, { name: 'BILLING', full: false }]
        ]
      },
      {
        exact: true,
        path: '/app/reports/discount',
        component: lazy(() => import('src/views/reports/DiscountView')),
        permissions: [
          [{ name: 'REPORTS', full: false }, { name: 'BILLING', full: false }]
        ]
      },
      {
        exact: true,
        path: '/app/reports/pending-refunds',
        component: lazy(() => import('src/views/reports/PendingRefundView')),
        permissions: [
          [{ name: 'REPORTS', full: false }, { name: 'BILLING', full: false }]
        ]
      },
      {
        exact: true,
        path: '/app/reports/asset',
        component: lazy(() => import('src/views/reports/AssetView')),
        permissions: [
          [{ name: 'REPORTS', full: false }, { name: 'BRANCHES', full: false }]
        ]
      },
      {
        exact: true,
        path: '/app/reports/minimum-usage-exceptions',
        component: lazy(() => import('src/views/reports/MinimumUsageExceptionsView')),
        permissions: [
          [{ name: 'REPORTS', full: false }, { name: 'CUSTOMERS', full: false }]
        ]
      },
      {
        exact: true,
        path: '/app/settings/periods',
        component: lazy(() => import('src/views/settings/periods/PeriodListView')),
        permissions: [
          [{ name: 'SETTINGS', full: false }, { name: 'BILLING', full: false }],
          [{ name: 'SETTINGS', full: false }, { name: 'SUBSCRIPTIONS', full: false }],
        ]
      },
      {
        exact: true,
        path: '/app/settings/billing-periods',
        component: lazy(() => import('src/views/settings/billingPeriods/BillingPeriodListView')),
        permissions: [
          [{ name: 'SETTINGS', full: false }, { name: 'BILLING', full: false }]
        ]
      },
      {
        exact: true,
        path: '/app/settings/allergies',
        component: lazy(() => import('src/views/settings/allergies/AllergyListView')),
        permissions: [
          [{ name: 'SETTINGS', full: false }, { name: 'PRODUCTION', full: false }],
          [{ name: 'SETTINGS', full: false }, { name: 'SUBSCRIPTIONS', full: false }],
        ]
      },
      {
        exact: true,
        path: '/app/settings/paymentMethods',
        component: lazy(() => import('src/views/settings/paymentMethods/PaymentMethodListView')),
        permissions: [
          [{ name: 'SETTINGS', full: false }, { name: 'BILLING', full: false }]
        ]
      },
      {
        exact: true,
        path: '/app/settings/taxRates',
        component: lazy(() => import('src/views/settings/taxRates/TaxRateListView')),
        permissions: [
          [{ name: 'SETTINGS', full: false }, { name: 'BILLING', full: false }]
        ]
      },
      {
        exact: true,
        path: '/app/settings/routes',
        component: lazy(() => import('src/views/settings/routes/index')),
        permissions: [
          [{ name: 'SETTINGS', full: false }, { name: 'BRANCHES', full: false }]
        ]
      },
      {
        exact: true,
        path: '/app/settings/blockList',
        component: lazy(() => import('src/views/settings/blockList/BlockListView')),
        permissions: [
          [{ name: 'ADMIN', full: false }]
        ]
      },
      {
        exact: true,
        path: '/app/settings/siblingDiscounts',
        component: lazy(() => import('src/views/settings/siblingDiscounts/SiblingDiscountsView')),
        permissions: [
          [{ name: 'SETTINGS', full: false }, { name: 'BILLING', full: false }]
        ]
      },
      {
        exact: true,
        path: '/app/panFiles',
        component: lazy(() => import('src/views/panFiles/PanFileListView')),
        permissions: [
          [{ name: 'PRODUCTION', full: false }]
        ]
      },
      {
        exact: true,
        path: '/app/panFiles/:panFileId',
        component: lazy(() => import('src/views/panFiles/PanFileDetailsView')),
        permissions: [
          [{ name: 'PRODUCTION', full: false }]
        ]
      },
      {
        exact: true,
        path: '/app/panFiles/:panFileId/summary',
        component: lazy(() => import('src/views/panFiles/PanFileSummaryView')),
        permissions: [
          [{ name: 'PRODUCTION', full: false }]
        ]
      },
      {
        exact: true,
        path: '/app/dailyReports',
        component: lazy(() => import('src/views/dailyReports/DailyReportListView')),
        permissions: [
          [{ name: 'DAILYREPORTS', full: false }]
        ]
      },
      {
        exact: true,
        path: '/app/dailyReports/add',
        component: lazy(() => import('src/views/dailyReports/DailyReportCreateView')),
        permissions: [
          [{ name: 'DAILYREPORTS', full: true }]
        ]
      },
      {
        exact: true,
        path: '/app/dailyReports/:dailyReportId',
        component: lazy(() => import('src/views/dailyReports/DailyReportDetailsView')),
        permissions: [
          [{ name: 'DAILYREPORTS', full: false }]
        ]
      },
      {
        exact: true,
        path: '/app/dailyReports/:dailyReportId/edit',
        component: lazy(() => import('src/views/dailyReports/DailyReportEditView')),
        permissions: [
          [{ name: 'DAILYREPORTS', full: true }]
        ]
      },
      {
        exact: true,
        path: '/app/403',
        component: lazy(() => import('src/views/errors/Error403View')),
        permissions: []
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

const renderRoutes = (routes, roles, admin) => (routes ? (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;
        const AccessDeniedComponent = lazy(() => import('src/views/errors/Error403View'));

        const hasPermission = (route) => {
          if (admin) {
            return true;
          }

          if (route.permissions == undefined || route.permissions.length == 0) {
            return true;
          }

          const result = route.permissions.some((roleGroup) => roleGroup.every((role) => roles.some((userRole) => userRole.name == role.name && (role.full ? userRole.pivot.full_permissions : true))));
          return result;
        };

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes
                    ? renderRoutes(route.routes, roles, admin)
                    : (
                      <>
                        {hasPermission(route)
                          ? <Component {...props} />
                          : <AccessDeniedComponent {...props} />}
                      </>
                    )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
) : null);

function Routes() {
  const account = useSelector((state) => state.account);
  const roles = account?.user?.roles || [];
  const admin = account?.user?.type_id == 2 || false;
  return renderRoutes(routesConfig, roles, admin);
}

export default Routes;
