import axios from 'src/utils/axios';
import { API_BASE_URL } from 'src/config';

export const GET_PURCHASE_ORDERS_PRODUCTS =
  '@purchase-orders-products/get-products';
export const REQUEST_PURCHASE_ORDERS_PRODUCTS =
  '@purchase-orders-products/request-products';

export function getPurchaseOrdersProducts(type) {
  const request = axios.get(`${API_BASE_URL}/purchase-form/products/${type}`);

  return (dispatch) => {
    dispatch({ type: REQUEST_PURCHASE_ORDERS_PRODUCTS })

    request
      .then((response) => {
        dispatch({
          type: GET_PURCHASE_ORDERS_PRODUCTS,
          payload: {
            purchaseOrderProduct: response.data
          }
        });
      })
      .catch((error) => {
        let message = '';
        if (error.response) {
          message = error.response.data;
        }

        dispatch({
          type: GET_PURCHASE_ORDERS_PRODUCTS,
          payload: { purchaseOrderProduct: [], message }
        });
      });
  };
}
